<template>
	<div>
		<div class="dialog">
			<el-form ref="ruleForm" label-width="80px">
				<el-form-item label="头像">
					<span class="xx"></span>
					<div class="dialog-up">
						<!-- 预览图 -->
						<img v-if="ruleForm.avatar" class="dialog-img" :src="ruleForm.avatar" />
						<p v-if="ruleForm.avatar" class="dele" @click="dele">删除</p>
						<el-upload :headers="Headers" :action="ActionUrl" :on-success="handleAvatarSuccess">
							<div class="upload">
								<i class="icon iconfont icon-shangchuan"></i>
							</div>
						</el-upload>
					</div>
				</el-form-item>
				<el-form-item label="排序">
					<span class="xx"></span>
					<el-input clearable v-model="ruleForm.sort" placeholder="请输入排序"></el-input>
				</el-form-item>
				<el-form-item label="用户名">
					<span class="xx">*</span>
					<el-input clearable :disabled="title == '编辑'?true:false" v-model="ruleForm.account"
						placeholder="请输入用户名(登录账号)"></el-input>
				</el-form-item>
				<el-form-item label="账号类型"  v-if="rolestype==1&&title == '新增'">
					<span class="xx">*</span>
					<el-select clearable v-model="ruleForm.type" @change="changetype" placeholder="请选择账号类型">
						<el-option label="管理员" :value="1"></el-option>
						<el-option label="接单员" :value="2"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="姓名">
					<span class="xx"></span>
					<el-input clearable v-model="ruleForm.real_name" placeholder="请输入姓名"></el-input>
				</el-form-item>

				<el-form-item label="手机号码">
					<span class="xx"></span>
					<el-input clearable type="number" v-model="ruleForm.phone" placeholder="请输入手机号码"></el-input>
				</el-form-item>

				<el-form-item label="选择县级" v-if="deparid==''&&ruleForm.type==2&&rolestype==1">
					<span class="xx"></span>
					<el-select clearable v-model="countyid" @change="changecounty" placeholder="请选择县级">
						<el-option v-for="item in county" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="选择镇级" v-if="countyid&&ruleForm.type==2&&rolestype==1">
					<span class="xx"></span>
					<el-select clearable v-model="townid" placeholder="请选择选择镇级">
						<el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="选择部门" v-if="countyid==''&&ruleForm.type==2&&rolestype==1">
					<span class="xx"></span>
					<el-select clearable v-model="deparid" @change="changedepar" placeholder="请选择部门">
						<el-option v-for="item in depar" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="二级部门" v-if="deparid&&ruleForm.type==2&&rolestype==1">
					<span class="xx"></span>
					<el-select clearable v-model="erdeparid" placeholder="请选择二级部门">
						<el-option v-for="item in erdepar" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>


				<el-form-item label="状态">
					<span class="xx">*</span>
					<el-select clearable v-model="ruleForm.status" placeholder="请选择状态">
						<el-option label="启用" :value="1"></el-option>
						<el-option label="禁用" :value="0"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="密码" v-if="title=='新增'">
					<span class="xx">*</span>
					<el-input type="password" show-password clearable v-model="ruleForm.password"
						placeholder="请输入8到16位数字与字母组合密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" v-if="title=='新增'">
					<span class="xx">*</span>
					<el-input type="password" show-password clearable v-model="ruleForm.qrpassword"
						placeholder="请输入确认密码">
					</el-input>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/user";
	export default {
		props: ['title', 'mid', 'identity'],
		data() {
			return {
				Headers: {
					authorization: '',
					appid:'',
					nonce:'',
					timestamp:'',
					sign:''
				},
				ActionUrl: '',
				outerVisible: false,
				ruleForm: {
					sort: 0,
					account: '',
					avatar: '',
					real_name: '',
					phone: '',
					type: '',
					status: 1,
					password: '',
					qrpassword: '',
					district_id: ''
				},
				county: [],
				countyid: '',
				townid: '',
				town: [],
				deparid: '',
				depar: [],
				erdepar: [],
				erdeparid: '',
				rolestype: ""
			}
		},
		mounted() {
			// 角色
			this.rolestype = this.$store.state.userinfo.roles
			this.getList()
			this.getdepar()
			// 上转图片url
			this.Headers.authorization = sessionStorage.getItem('token')
			let param = JSON.parse(sessionStorage.getItem('param'))
			this.Headers.appid = param.appid
			this.Headers.nonce = param.nonce
			this.Headers.timestamp = param.timestamp
			this.Headers.sign = param.sign
			this.ActionUrl = sessionStorage.getItem('baseURL') + '/System/FormUpload'
			if (this.title == '编辑') {
				this.getread()
			}
		},
		methods: {
			// 账号类型
			changetype() {
				this.townid = ""
				this.erdeparid = ""
			},
			// 获取二级部门
			geterdepar(id) {
				this.$http.post(api.countylists, {
						page: 1,
						limit: 99999,
						parent_id: id,
						type: 1
					})
					.then((res) => {
						if (res.code == 1) {
							this.erdepar = res.data.list
						}
					})
			},
			// 选择一级部门
			changedepar(e) {
				this.countyid = ""
				this.townid = ""
				this.erdeparid = ""
				this.town = []
				this.geterdepar(e)
			},
			// 获取一级部门
			getdepar() {
				this.$http.post(api.countylists, {
						page: 1,
						limit: 99999,
						parent_id: 0,
						type: 1
					})
					.then((res) => {
						if (res.code == 1) {
							this.depar = res.data.list
						}
					})
			},

			// 获取镇
			gettown(id) {
				this.$http.post(api.countylists, {
						page: 1,
						limit: 99999,
						parent_id: id,
						type: 0
					})
					.then((res) => {
						if (res.code == 1) {
							this.town = res.data.list
						}
					})
			},
			// 县选择
			changecounty(e) {
				this.deparid = ""
				this.erdeparid = ""
				this.erdepar = []
				this.townid = ""
				this.gettown(e)
			},
			// 获取县
			getList() {
				this.$http.post(api.countylists, {
						page: 1,
						limit: 99999,
						parent_id: 0,
						name: '',
						type: 0
					})
					.then((res) => {
						if (res.code == 1) {
							this.county = res.data.list
						}
					})
			},

			// 获取详情
			getread() {
				this.$http.post(api.read, {
						id: this.mid
					})
					.then((res) => {
						if (res.code == 1) {
							this.ruleForm = res.data
						}
					})
			},

			// 删除图片
			dele() {
				this.ruleForm.avatar = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.avatar = res.data
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
			},
			//新增/编辑
			SubmitForm() {
				this.ruleForm.district_id = (this.townid || this.countyid) || (this.erdeparid || this.deparid)
				if (this.ruleForm.account === '') {
					ElMessage({
						message: '请输入用户名',
						type: 'warning',
					})
				} else if (this.ruleForm.type === ''&&this.rolestype==1) {
					ElMessage({
						message: '请选择账号类型',
						type: 'warning',
					})
				} else if (this.ruleForm.status === '') {
					ElMessage({
						message: '请选择状态',
						type: 'warning',
					})
				} else if (!(/^1[3456789]\d{9}$/.test(this.ruleForm.phone)) && this.ruleForm.phone) {
					ElMessage({
						message: '请输入正确的号码格式',
						type: 'warning',
					})
				} else if (this.ruleForm.password === '' && this.title == '新增') {
					ElMessage({
						message: '请输入密码',
						type: 'warning',
					})
				} else if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(this.ruleForm.password)) && this.title ==
					'新增') {
					ElMessage({
						message: '请输入8到16位数字与字母组合密码',
						type: 'warning',
					})
				} else if (this.ruleForm.qrpassword === '' && this.title == '新增') {
					ElMessage({
						message: '请输入确认密码',
						type: 'warning',
					})
				} else if (this.ruleForm.qrpassword != this.ruleForm.password && this.title == '新增') {
					ElMessage({
						message: '密码不一致',
						type: 'warning',
					})
				} else {
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}

				}

			},
			// 编辑
			edit() {
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$EventBus.emit('RefreshUser')
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			},
			// 新增
			add() {
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 1) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.$emit('close')
							// 刷新列表
							this.$emit('SubmitForm')
						}
					})
			}
		}
	}
</script>

<style scoped>
</style>
